export default {
  data() {
    return {
      errorMessages: {
        required: 'This value is required',
        requiredIf: 'This value is required',
        maxLength: 'The value is too long',
        minValue: 'The value is below minimum value',
        minLength: 'The value is too short',
        maxValue: 'The value is above expected max value',
        emailFormat: 'This email doesn\'t appear to be valid',
        email: 'This email doesn\'t appear to be valid',
        numeric: 'This field must be a number',
        payerIsUnique: 'Payer already added',
        invalidCharacter: 'Invalid character',
        uniqueProviderNameValidator: 'Provider name must be unique.',
        uniquePathValidator: 'File path must be unique.',
        uniqueColumnNumber: 'Column number should be unique',
        minLimit: 'Minimum value is 1',
        numberCsvValidator: 'Must be single number (3) or comma separated (3,6)',
        alpha: 'The value can only contain letters',
        integer: 'The value must be an integer',
        validEmailList: 'Invalid email list. Please check if all emails are valid and spited using a comma (,)',
        validDateFormat: 'The provided format is not valid',
        validFilename: 'Invalid filename sintax',
      },
    };
  },
  methods: {
    handleError(field) {
      const validator = this.getValidator(field);
      if (!validator.$invalid) {
        return '';
      }

      let errorMsg = '';
      Object.keys(validator.$params).forEach(validationKey => {
        if (!validator[validationKey]) {
          errorMsg = this.getErrorMessage(validationKey);
        }
      });

      return errorMsg;
    },
    getValidator(field) {
      const parts = field.split('.');
      let rootValidator = this.$v;

      parts.forEach(part => {
        rootValidator = rootValidator[part] || {};
      });

      return rootValidator;
    },
    isValid(field) {
      const validator = this.getValidator(field);
      const invalid = validator.$invalid;
      const value = validator.$model;

      if (!value && !('required' in validator) && !('requiredIf' in validator) && !('requiredUnless' in validator) && !('url' in validator)) {
        return true;
      }

      return !invalid;
    },
    getErrorMessage(validationKey) {
      return this.errorMessages[validationKey] || validationKey;
    },
  },
};
