<template>
  <div class="container mt-4">
    <b-row>
      <b-col class="d-flex align-items-center">
        <h5 class="ml-2 mb-0">
          {{ translations.details.page_title }} {{ dealDetails.id }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          variant="primary"
          @click="updateDealDetail">
          {{ translations.details.save_btn }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-card
        class="border-0 w-100"
        body-class="px-0"
        header-class="border-0"
        data-testid="details-card">
          <b-col>
            <b-row cols="5" class="mb-5">
              <b-col>
                <b>{{ translations.details.deal_name }}</b>
                <p>{{ dealDetails.name || '-' }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.close_date }}</b>
                <p>{{ formatDate(dealDetails.close_date) }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.opportunity_type }}</b>
                <p>{{ dealDetails.opportunity_type || '-' }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.opportunity_owner }}</b>
                <p>{{ dealDetails.opportunity_owner_name || '-' }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.carr }}</b>
                <p>{{ dealDetails.amount_carr || '-' }}</p>
              </b-col>
            </b-row>
            <b-row cols="5" class="mb-5">
              <b-col>
                <b>{{ translations.details.sfdc_opportunity_id }}</b>
                <p>{{ dealDetails.opportunity_id || '-' }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.sfdc_account_id }}</b>
                <p>{{ dealDetails.account_id || '-' }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.sfdc_parent_opportunity_id }}</b>
                <p>{{ dealDetails.parent_opportunity_id || '-' }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.business_segment }}</b>
                <p>{{ dealDetails.business_segment || '-' }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.eligible_lives }}</b>
                <p>{{ dealDetails.eligible_lives || '-' }}</p>
              </b-col>
            </b-row>
            <b-row cols="5" class="mb-5">
              <b-col>
                <b>{{ translations.details.aso_channel }}</b>
                <p>{{ dealDetails.aso_channel ? "Yes" : "No" }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.aso_channel_live_date }}</b>
                <b-form-group v-if="dealDetails.aso_channel && !dealDetails.aso_channel_live_date"
                  class="live-date-padding">
                    <VueCtkDateTimePicker format="YYYY-MM-DD"
                                          :min-date="minDateAvailable"
                                          label="Live Date"
                                          formatted="ll"
                                          :no-button-now="true"
                                          :only-date="true"
                                          v-model="detailsForm.aso_channel_live_date"
                                          id="liveDate"/>
                </b-form-group>
                <p v-else>{{ formatDate(dealDetails.aso_channel_live_date) }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.aso_channel_deal_id }}</b>
                <p v-if="dealDetails.aso_channel_deal_id">
                 <b-link :to="`/commissions/deals/${dealDetails.aso_channel_deal_id}`" target="_blank">
                    {{ dealDetails.aso_channel_deal_id }}
                  </b-link>
                </p>
                <p v-else>-</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.aso_channel_close_date }}</b>
                <p>{{ formatDate(dealDetails.aso_channel_close_date) }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.details.plan_name }}</b>
                <p>{{ dealDetails.plan && dealDetails.plan.name ? dealDetails.plan.name : '-' }}</p>
              </b-col>
            </b-row>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
    <deal-client
      :dealClients="dealClients"
      :clients="clients"
      :loading="loading"
      :disabled="haveProductOldDate"
      :dealID="dealID"
      ref="dealClientComponent"/>
    <b-card class="border-0" :header="translations.details.product_table.title" header-class="border-0">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" class="col-md-1">{{ translations.details.product_table.id }}</th>
            <th scope="col" class="col-md-2">{{ translations.details.product_table.sfdc_opportunity_product_id }}</th>
            <th scope="col" class="col-md-1">{{ translations.details.product_table.product_code }}</th>
            <th scope="col" class="col-md-1">{{ translations.details.product_table.carr }}</th>
            <th scope="col" class="col-md-1">{{ translations.details.product_table.eligible_lives }}</th>
            <th scope="col" class="col-md-1">{{ translations.details.product_table.unit }}</th>
            <th scope="col" class="col-md-2">{{ translations.details.product_table.marketing_launch_date }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!dealProducts.length">
            <td colspan="7" class="text-center">
              {{ translations.errors.empty_table }}
            </td>
          </tr>
          <tr v-for="(product, index) in dealProducts" :key="index">
            <td class="align-middle">{{ product.id }}</td>
            <td class="align-middle">{{ product.opportunityID }}</td>
            <td class="align-middle">{{ product.productCode }}</td>
            <td class="align-middle">{{ product.carr }}</td>
            <td class="align-middle">{{ product.eligibleLives }}</td>
            <td class="align-middle">{{ product.swordUnit || "-" }}</td>
            <td v-if="product.isOldDate || !product.swordUnit" class="align-middle">{{ product.swordLaunchDate || "-" }}</td>
            <td v-else class="align-middle">
              <b-form-group
                class="live-date-padding">
                  <VueCtkDateTimePicker format="YYYY-MM-DD"
                                        :min-date="minDateAvailable"
                                        label="Launch Date"
                                        formatted="ll"
                                        :no-button-now="true"
                                        :noClearButton="product.swordLaunchDateIsInDb"
                                        :only-date="true"
                                        v-model="productsForm[index].sword_launch_date"
                                        :id="`launchDate${index}`"/>
              </b-form-group>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <b-card class="border-0" :header="translations.details.split_table.title" header-class="border-0">
      <financial-list
        v-model="dealSplitsCurrentPage"
        :header-data="TABLE_HEADERS"
        :data="dealSplits"
        itemRefKey='id'
        :havePagination="false"
        :no-items-message="translations.errors.empty_table"/>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment';
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import { parseResponseError } from '@/http/parsers/parse_response';
import service from '@/services/commission';
import translations from '@/translations';
import { formatDate } from '@/helpers/finance';
import DealClient from '@/views/Commissions/Deals/Details/dealClient.vue';

export default {
  components: {
    FinancialList,
    DealClient,
  },
  name: 'DealsDetails',
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.details.split_table.id, 'id'),
      associateHeaderDataItem(this.translations.details.split_table.rvp_name, 'rvp_name'),
      associateHeaderDataItem(this.translations.details.split_table.split_percentage, 'split_percentage'),
      associateHeaderDataItem(this.translations.details.split_table.sfdc_split_id, 'sfdc_split_id'),
    ];
  },
  data() {
    return {
      dealID: Number(this.$route?.params?.dealID || 0),
      translations: translations.commissions.deals,
      loading: true,
      // tables and important things
      dealDetails: {},
      clients: [ { value: null, text: '-' } ],
      dealProducts: [],
      dealClients: [],
      dealSplits: [],
      dealSplitsCurrentPage: 1,
      // forms and other
      detailsForm: {
        aso_channel_live_date: null,
      },
      productsForm: [],
      haveProductOldDate: false,
      clientIDs: [],
    };
  },
  async beforeMount() {
    this.loading = true;
    await this.fetchDeal();
    await this.fetchClients();
    this.loading = false;
  },
  computed: {
    minDateAvailable() {
      return moment().utc().add(1, 'day').format('YYYY-MM-DD');
    },
  },
  methods: {
    formatDate,
    async fetchDeal() {
      try {
        const dealDetails = await service.getDeal(this.dealID);
        this.dealDetails = dealDetails;

        this.formatDealSplits(dealDetails?.splits);
        this.formatDealProducts(dealDetails?.products);
        this.dealClients = dealDetails?.sword_clients;
      } catch (err) {
        this.$noty.error(`${this.translations.errors.fetch_details}: ${parseResponseError(err)}`);
      }
    },
    async updateDealDetail() {
      const isValid = this.validateFieldsToUpdate();
      if (!isValid) {
        return;
      }

      this.loading = true;
      try {
        const body = this.buildUpdateBody();

        const deal = await service.updateDeal(this.dealID, body);
        this.dealDetails.aso_channel_live_date = deal.aso_channel_live_date;
        this.dealClients = deal?.sword_clients;
        this.formatDealProducts(deal?.products);
        this.$noty.success(this.translations.success.update);
      } catch (err) {
        this.$noty.error(`${this.translations.errors.update}: ${parseResponseError(err)}`);
      }
      this.loading = false;
    },
    validateFieldsToUpdate() {
      if (
        this.dealDetails.aso_channel
        && !this.dealDetails.aso_channel_live_date
        && this.detailsForm.aso_channel_live_date == null
      ) {
        this.$noty.error(this.translations.errors.required_live_date);
        return false;
      }

      if (!this.dealDetails.aso_channel && this.$refs.dealClientComponent.dealClientTable.length <= 0) {
        this.$noty.error(this.translations.errors.required_aso_groups);
        return false;
      }

      return true;
    },
    async fetchClients() {
      const result = await this.$store.dispatch('Core/fetchAllClients');

      if (result.length > 0) {
        const resultSorted = result.sort((a, b) => a.display_name.localeCompare(b.display_name));

        this.clients = [
          { value: null, text: 'Select option' },
        ];
        this.clientIDs = [ null ];
        const clients = [];

        resultSorted.forEach(client => {
          this.clientIDs.push(client.id);

          clients.push({
            value: client.id,
            text: `${client.display_name} (ID: ${client.id})`,
            sword_client_display_name: client.display_name,
            sword_client_name: client.name,
            sword_client_id: client.id,
            deal_id: this.dealID,
          });
        });

        this.clients = [
          { value: null, text: 'Select option' },
          ...clients,
        ];
      }
    },
    buildUpdateBody() {
      this.$refs.dealClientComponent.assignDataToTheCorrespondingTableColumn();

      const body = {};

      if (this.detailsForm.aso_channel_live_date) {
        body.aso_channel_live_date = this.detailsForm.aso_channel_live_date;
      }

      const clientsToUpdate = [];
      const { dealClientIDInDB } = this.$refs.dealClientComponent;
      this.$refs.dealClientComponent.dealClientTable.forEach(clientAso => {
        const clientIndex = this.clientIDs.indexOf(clientAso.client);
        const client = this.clients[clientIndex];
        const clientToPush = {
          deal_id: client.deal_id,
          sword_client_display_name: client.sword_client_display_name,
          sword_client_id: client.sword_client_id,
          sword_client_name: client.sword_client_name,
        };

        if (clientAso.asoGroup && clientAso.asoGroup.length > 0) {
          clientToPush.aso_groups = clientAso.asoGroup.map(group => {
            const asoGroupToApi = {
              aso_group_id: group.aso_group_id,
              aso_group_name: group.aso_group_name,
              deal_id: group.deal_id,
            };

            if (group.id) asoGroupToApi.id = group.id;

            return asoGroupToApi;
          });
        }

        if (clientAso.lineOfBusiness && clientAso.lineOfBusiness.length > 0) {
          clientToPush.line_of_businesses = clientAso.lineOfBusiness.map(item => {
            const lobToApi = {
              line_of_business_id: item.line_of_business_id,
              line_of_business_name: item.line_of_business_name,
            };

            if (item.id) lobToApi.id = item.id;

            return lobToApi;
          });
        }

        if (dealClientIDInDB[clientAso.client]) {
          clientToPush.id = dealClientIDInDB[clientAso.client];
        }

        clientsToUpdate.push(clientToPush);
      });

      if (clientsToUpdate.length > 0) {
        body.sword_clients = clientsToUpdate;
      }

      const productsToUpdate = [];
      this.productsForm.forEach(product => {
        if (product.sword_launch_date) {
          productsToUpdate.push(product);
        }
      });

      if (productsToUpdate.length > 0) {
        body.products = productsToUpdate;
      }

      return body;
    },
    formatDealSplits(splits = []) {
      this.dealSplits = splits.map(split => (
        {
          id: split.id,
          rvp_name: split?.rvp?.name,
          split_percentage: split.split_percentage,
          sfdc_split_id: split.salesforce_opportunity_split_id,
        }
      ));
    },
    formatDealProducts(products = []) {
      this.productsForm = [];
      this.dealProducts = products.map(product => {
        let isOldDate = false;
        if (product.sword_launch_date && moment(product.sword_launch_date).isSameOrBefore(moment().utc(), 'day')) {
          isOldDate = true;
          this.haveProductOldDate = true;
        }

        this.productsForm.push({
          id: product.id,
          sword_launch_date: product.sword_launch_date ? formatDate(product.sword_launch_date) : null,
        });

        return {
          id: product.id,
          opportunityID: product.opportunity_product_id,
          productCode: product.opportunity_product_code,
          carr: product.amount_carr,
          eligibleLives: product.eligible_lives,
          swordUnit: product.sword_unit,
          swordLaunchDate: formatDate(product.sword_launch_date),
          isOldDate,
          swordLaunchDateIsInDb: !!product.sword_launch_date,
        };
      });
    },
  },
};
</script>

<style lang="scss">
  .live-date-padding{
    padding-left: 0px;
    padding-right: 1rem;
  }
</style>
