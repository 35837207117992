<template>
  <b-card class="border-0" no-body>
    <b-card-header class="border-0">
      {{ translations.details.deal_client_table.title }}
      <b-badge variant="light" v-b-tooltip.hover :title="translations.details.deal_client_table.info_badge">
        <feather type="info" class="text-info"/>
      </b-badge>
    </b-card-header>
    <b-card-body>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="col-md-2">{{ translations.details.deal_client_table.client_name }}</th>
            <th scope="col" class="col-md-4">{{ translations.details.deal_client_table.line_of_business }}</th>
            <th scope="col" class="col-md-4">{{ translations.details.deal_client_table.aso_group }}</th>
            <th scope="col" class="col-md-1">{{ translations.details.deal_client_table.actions }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!dealClientTable.length">
            <td colspan="4" class="text-center">
              {{ translations.errors.empty_table }}
            </td>
          </tr>
          <tr v-for="(item, index) in dealClientTable" :key="index">
            <td class="align-top">
              <b-form-group
                :invalid-feedback="handleError('dealClientFormEdit.client')"
                :state="item.editDisabled || isValid('dealClientFormEdit.client')">
                <b-select v-if="item.editDisabled"
                  v-model="item.client"
                  :options="clients"
                  :disabled="true"/>
                <b-select v-else
                  v-model="dealClientFormEdit.client"
                  :options="clients"
                  @change="clientID => onChangeClient(clientID, true)"/>
              </b-form-group>
            </td>
            <td class="align-top">
              <b-form-group class="mx-0 px-0 mw-20">
                <multiselect v-if="item.editDisabled"
                  v-model="item.lineOfBusiness"
                  :options="item.lineOfBusiness"
                  :multiple="true"
                  :showLabels="false"
                  label="text"
                  :disabled="true"
                  track-by="line_of_business_id"/>
                <multiselect v-else
                  v-model="dealClientFormEdit.lineOfBusiness"
                  :options="lineOfBusinessOptionsEdit"
                  :multiple="true"
                  :showLabels="false"
                  label="text"
                  track-by="line_of_business_id"/>
              </b-form-group>
            </td>
            <td class="align-top">
              <b-form-group class="mx-0 px-0 mw-20">
                <multiselect v-if="item.editDisabled"
                  v-model="item.asoGroup"
                  :options="item.asoGroup"
                  :multiple="true"
                  :showLabels="false"
                  label="text"
                  :disabled="true"
                  track-by="aso_group_id"/>
                <multiselect v-else
                  v-model="dealClientFormEdit.asoGroup"
                  :options="asoGroupsOptionsEdit"
                  :multiple="true"
                  :showLabels="false"
                  label="text"
                  track-by="aso_group_id"/>
              </b-form-group>
            </td>
            <td class="d-flex flex-column flex-xl-row align-items-center justify-content-center justify-content-xl-around">
              <b-button
                type="button"
                class="align-self-end"
                variant="secondary"
                :disabled="disabled || loading"
                @click="deleteItem(index)">
                <feather type="minus"></feather>
              </b-button>
              <b-button v-if="item.editDisabled"
                type="button"
                class="align-self-end"
                :disabled="disabled || loading"
                variant="secondary"
                @click="enableEdit(index)">
                <feather type="edit-2"></feather>
              </b-button>
            </td>
          </tr>
          <tr>
            <td class="align-top">
              <b-form-group
                :invalid-feedback="handleError('dealClientForm.client')"
                :state="!dealClientValidateRequiredFields || isValid('dealClientForm.client')">
                <b-select
                  v-model="dealClientForm.client"
                  :options="clients"
                  :disabled="disabled || loading"
                  @change="onChangeClient"/>
              </b-form-group>
            </td>
            <td class="align-top">
              <b-form-group class="mx-0 px-0 mw-20">
                <multiselect
                  v-model="dealClientForm.lineOfBusiness"
                  :options="lineOfBusinessOptions"
                  :multiple="true"
                  :showLabels="false"
                  label="text"
                  :disabled="disabled || loading"
                  track-by="line_of_business_id"/>
              </b-form-group>
            </td>
            <td class="align-top">
              <b-form-group class="mx-0 px-0 mw-20">
                <multiselect
                  v-model="dealClientForm.asoGroup"
                  :options="asoGroupsOptions"
                  :multiple="true"
                  :showLabels="false"
                  label="text"
                  :disabled="disabled || loading"
                  track-by="aso_group_id"/>
              </b-form-group>
            </td>
            <td class="align-top">
              <b-button
                type="button"
                class="align-self-end"
                variant="secondary"
                :disabled="disabled || loading"
                @click="addItem">
                <feather type="plus"></feather>
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card-body>
  </b-card>
</template>

<script>
import translations from '@/translations';
import errorHandler from '@/mixins/errorHandler';
import { required } from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import eligibilityService from '@/services/eligibility-service';

export default {
  components: {
    Multiselect,
  },
  name: 'DealClient',
  mixins: [ errorHandler ],
  props: {
    dealClients: {
      type: Array,
      required: true,
    },
    clients: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    dealID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      translations: translations.commissions.deals,
      dealClientTable: [],
      dealClientForm: {
        client: null,
        asoGroup: null,
        lineOfBusiness: null,
      },
      dealClientFormEdit: {
        client: null,
        asoGroup: null,
        lineOfBusiness: null,
      },
      dealClientValidateRequiredFields: false,
      asoGroupsOptions: [],
      asoGroupsOptionsEdit: [],
      lineOfBusinessOptions: [],
      lineOfBusinessOptionsEdit: [],
      allClientID: [],
      currentlyEditItem: null,
      dealClientIDInDB: {},
    };
  },
  validations() {
    return this.rules;
  },
  computed: {
    rules() {
      return {
        dealClientForm: {
          client: { required },
        },
        dealClientFormEdit: {
          client: { required },
        },
      };
    },
  },
  watch: {
    dealClients(newVal) {
      this.formatDealClient(newVal);
    },
  },
  async beforeMount() {
    await this.formatDealClient(this.dealClients);
  },
  methods: {
    formatDealClient(dealClients = []) {
      this.dealClientTable = [];
      this.allClientID = [];
      dealClients.forEach(client => {
        // set existing aso groups to the multiselect
        const asoGroupsOptions = client.aso_groups.map(asoGroup => {
          const asoOption = {
            id: asoGroup.id,
            aso_group_id: asoGroup.aso_group_id,
            aso_group_name: asoGroup.aso_group_name,
            deal_id: this.dealID,
            text: asoGroup.aso_group_id,
          };

          if (asoGroup.aso_group_name && asoGroup.aso_group_name !== '') {
            asoOption.text += ` - ${asoGroup.aso_group_name}`;
          }

          return asoOption;
        });

        const lobOptions = client.line_of_businesses?.map(lob => {
          const lobOption = {
            id: lob.id,
            line_of_business_id: lob.line_of_business_id,
            line_of_business_name: lob.line_of_business_name,
            text: lob.line_of_business_name,
          };

          return lobOption;
        }) || [];

        this.dealClientTable.push({
          client: client.sword_client_id,
          asoGroup: asoGroupsOptions,
          lineOfBusiness: lobOptions,
          editDisabled: true,
        });

        // mapping the customer id with his id in the database
        this.dealClientIDInDB[client.sword_client_id] = client.id;
        this.allClientID.push(client.sword_client_id);
      });
    },
    validateDuplicateClients(clientID) {
      if (this.allClientID.indexOf(clientID) >= 0) {
        return false;
      }
      return true;
    },
    async onChangeClient(clientID, inTable = false) {
      if (inTable) {
        const valid = this.validateDuplicateClients(clientID);
        if (!valid) {
          this.$noty.error(this.translations.errors.duplicate_client);
          this.dealClientFormEdit.client = this.dealClientTable[this.currentlyEditItem].client;
          return;
        }
      }

      const tenants = await this.fetchTenants(clientID);
      const asoOptions = tenants.map(tenant => {
        const asoOption = {
          aso_group_id: tenant.aso_group,
          aso_group_name: tenant.aso_group_name,
          deal_id: this.dealID,
          text: tenant.aso_group,
        };

        if (tenant.aso_group_name && tenant.aso_group_name !== '') {
          asoOption.text += ` - ${tenant.aso_group_name}`;
        }

        return asoOption;
      });

      const { lobs } = await eligibilityService.getLineOfBusinesses(clientID, { limit: 500 });
      const lobOptions = lobs.map(lob => {
        const lobOption = {
          line_of_business_id: lob.id,
          line_of_business_name: lob.line_of_business_name,
          text: lob.line_of_business_name,
        };

        return lobOption;
      });

      if (inTable) {
        this.asoGroupsOptionsEdit = asoOptions;
        this.lineOfBusinessOptionsEdit = lobOptions;
        this.dealClientFormEdit.asoGroup = null;
        this.dealClientFormEdit.lineOfBusiness = null;
        this.allClientID[this.currentlyEditItem] = clientID;
        this.dealClientTable[this.currentlyEditItem] = {
          client: clientID,
          asoGroup: [],
          lineOfBusiness: [],
        };
        return;
      }

      this.lineOfBusinessOptions = lobOptions;
      this.asoGroupsOptions = asoOptions;
      this.dealClientForm.asoGroup = null;
      this.dealClientForm.lineOfBusiness = null;
    },
    addItem() {
      this.dealClientValidateRequiredFields = true;
      if (this.$v.dealClientForm.$invalid) {
        return;
      }

      const valid = this.validateDuplicateClients(this.dealClientForm.client);
      if (!valid) {
        this.$noty.error(this.translations.errors.duplicate_client);
        return;
      }
      this.allClientID.push(this.dealClientForm.client);

      this.dealClientTable.push({
        client: this.dealClientForm.client,
        asoGroup: this.dealClientForm.asoGroup || [],
        lineOfBusiness: this.dealClientForm.lineOfBusiness || [],
        editDisabled: true,
      });

      this.dealClientForm = {
        client: null,
        asoGroup: null,
        lineOfBusiness: null,
      };

      this.asoGroupsOptions = [];
      this.lineOfBusinessOptions = [];
      this.dealClientValidateRequiredFields = false;
    },
    async fetchTenants(clientId) {
      await this.$store.dispatch('Eligibility/fetchTenants', { clientId });
      return this.$store.getters['Eligibility/getTenants'];
    },
    async enableEdit(index) {
      if (this.currentlyEditItem !== null) {
        this.dealClientTable[this.currentlyEditItem] = this.dealClientFormEdit;
        this.dealClientTable[this.currentlyEditItem].editDisabled = true;
      }

      // load aso groups for the client
      const tenants = await this.fetchTenants(this.dealClientTable[index].client);
      this.asoGroupsOptionsEdit = tenants.map(tenant => {
        const asoOption = {
          aso_group_id: tenant.aso_group,
          aso_group_name: tenant.aso_group_name,
          deal_id: this.dealID,
          text: tenant.aso_group,
        };

        if (tenant.aso_group_name && tenant.aso_group_name !== '') {
          asoOption.text += ` - ${tenant.aso_group_name}`;
        }

        return asoOption;
      });

      const { lobs } = await eligibilityService.getLineOfBusinesses(this.dealClientTable[index].client, { limit: 500 });
      this.lineOfBusinessOptionsEdit = lobs.map(lob => {
        const lobOption = {
          line_of_business_id: lob.id,
          line_of_business_name: lob.line_of_business_name,
          text: lob.line_of_business_name,
        };

        return lobOption;
      });

      this.dealClientFormEdit = {
        client: this.dealClientTable[index].client,
        asoGroup: this.dealClientTable[index].asoGroup,
        lineOfBusiness: this.dealClientTable[index].lineOfBusiness,
      };
      this.dealClientTable[index].editDisabled = false;
      this.currentlyEditItem = index;
    },
    deleteItem(clientAsoIndex) {
      this.dealClientTable.splice(clientAsoIndex, 1);
      this.allClientID.splice(clientAsoIndex, 1);

      if (clientAsoIndex === this.currentlyEditItem) {
        this.currentlyEditItem = null;
        this.dealClientFormEdit = {
          client: null,
          asoGroup: null,
          lineOfBusiness: null,
        };
      }
    },
    assignDataToTheCorrespondingTableColumn() {
      if (this.dealClientFormEdit.client !== null || this.dealClientFormEdit.lineOfBusiness !== null) {
        this.dealClientTable[this.currentlyEditItem] = this.dealClientFormEdit;
      }
    },
  },
};
</script>
